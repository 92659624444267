import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Development = () => (
  <Layout>
    <Seo title="Privacy Policy NSC" />
    <div>Privacy Policy</div>
    <div class="container max-width-80 margin-auto color-white">
      <h1>Privacy</h1>
      <p>
        This privacy policy sets out how Daniel Roberts (trading name for
        NonStop Coding) uses and protects any information that you give him when
        you use this website.
      </p>
      <p>
        I respect the EU’s General Data Protection Regulations (GDPR) and this
        policy explains how I collect and treat any information you give me. You
        won’t find any complicated legal terms or long passages of unreadable
        text. I’ve no desire to trick you into agreeing to something you might
        later regret.
      </p>
      <p>
        I value your privacy as much as I do my own, so I'm committed to keeping
        your personal and business information safe. I'm uncomfortable with the
        information companies, governments, and other organisations keep on
        file, so I ask for only the bare minimum from my customers. I’ll never
        use your personal information for any reason other than why you gave it,
        and I’ll never give anyone access to it unless I'm forced to by law.
      </p>
      <p>
        My Data Controller is Daniel Roberts, Founder. You can contact me via
        the online contact form
      </p>
      <p>
        I'm committed to ensuring that your privacy is protected. Should I ask
        you to provide certain information by which you can be identified when
        using this website, then you can be assured that it will only be used in
        accordance with this privacy statement.
      </p>
      <p>
        I may change this policy from time to time by updating this page. You
        should check this page from time to time to ensure that you are happy
        with any changes. This policy is effective from January 2022.
      </p>
      <h2>Information You Provide to Me</h2>
      <ul>
        <li>
          <strong>Personal data:</strong> to be able to contact you, I need your
          name, email address and contact details.
        </li>
        <li>
          <strong>Communications with me:</strong> you may provide information
          when contacting me for support, or speaking with me via another secure
          channel, and I may keep records of that communication.
        </li>
      </ul>
      <h2>Information I collect automatically</h2>
      <p>
        In running and maintaining my website I may collect and process the
        following data about you:
      </p>
      <ul>
        <li>
          <strong>Analytics data:</strong> Information about your use of my site
          including details of your visits such as pages viewed and the
          resources that you access. Such information includes traffic data,
          location data, and other communication data.
        </li>
        <li>
          <strong>Enquiry data</strong> I ask for contact information including
          your name, email address, and phone number, on my website so that I
          can reply to your enquiry.
        </li>
        <li>
          <strong>Account data</strong> I ask for your account and contact
          information when you hire or buy something from me.
        </li>
      </ul>
      <p>
        Occasionally, I might receive your contact information from one of my
        partners. If I do, I protect it in exactly the same way as if you give
        it to me directly.
      </p>
      <h2>What I Do With Information I Gather</h2>
      <p>
        I require this information to understand your needs and provide you with
        a better service, and in particular for the following reasons:
      </p>
      <ul>
        <li>Internal record keeping.</li>
        <li>I may use the information to improve my products and services.</li>
        <li>
          I may periodically send promotional emails about new products, special
          offers or other information which I think you may find interesting
          using the email address which you have provided ticked to confirm I
          can send you this.
        </li>
        <li>
          From time to time, I may also use your information to contact you for
          market research purposes. I may contact you by email, phone, fax or
          mail. I may use the information to customise the website according to
          your interests.
        </li>
        <li>
          Im located in the United Kingdom. My server is hosted in London UK by
          <a class="color-white" href="https://siteground.co.uk">
            SiteGround.
          </a>
        </li>
      </ul>
      <p>
        In operating my website it may become necessary to transfer data that I
        collect from you to locations outside of the European Union for
        processing and storing. By providing your personal data to me, you agree
        to this transfer, storing or processing. I do my utmost to ensure that
        all reasonable steps are taken to make sure that your data is treated
        stored securely.
      </p>
      <p>
        In the interest of transparency, the following details are some of (but
        not limited to) the third party services I use to provide my service to
        you:
      </p>
      <ul>
        <li>Google Analytics</li>
        <li>Mailchimp</li>
        <li>Stripe</li>
        <li>Shopify</li>
        <li>AWS</li>
      </ul>
      <h2>Security</h2>
      <p>
        I'm committed to ensuring that your information is secure. In order to
        prevent unauthorized access or disclosure, I have put in place suitable
        physical, electronic and managerial procedures to safeguard and secure
        the information I collect online.
      </p>
      <h2>How I Use Cookies</h2>
      <p>
        A cookie is a small file which asks permission to be placed on your
        computer’s hard drive. Once you agree, the file is added and the cookie
        helps analyse web traffic or lets you know when you visit a particular
        site. Cookies allow web applications to respond to you as an individual.
        The web application can tailor its operations to your needs, likes and
        dislikes by gathering and remembering information about your
        preferences.
      </p>
      <p>
        I use traffic log cookies to identify which pages are being used. This
        helps me analyse data about web page traffic and improve my website in
        order to tailor it to customer needs. I only use this information for
        statistical analysis purposes and then the data is removed from the
        system.
      </p>
      <p>
        Overall, cookies help me provide you with a better website, by enabling
        me to monitor which pages you find useful and which you do not. A cookie
        in no way gives me access to your computer or any information about you,
        other than the data you choose to share with me.
      </p>
      <p>
        You can choose to accept or decline cookies. Most web browsers
        automatically accept cookies, but you can usually modify your browser
        setting to decline cookies if you prefer. This may prevent you from
        taking full advantage of the website.
      </p>
      <h2>Your Information</h2>
      <p>
        I use the information that I collect from you to provide my services to
        you. In addition to this I may use the information for one or more of
        the following purposes:
      </p>
      <ul>
        <li>
          To provide information to you that you request from me relating to my
          products or services.
        </li>
        <li>
          To provide information to you relating to other products that may be
          of interest to you. Such additional information will only be provided
          where you have consented to receive such information.
        </li>
        <li>
          To inform you of any changes to my website, services or goods and
          products.
        </li>
        <li>
          If you have previously purchased from me I may provide to you details
          of similar goods or services, or other goods and services, that you
          may be interested in.
        </li>
      </ul>
      <p>
        All marketing emails sent by me will have a clear unsubscribe link. I do
        not pass on your information to third parties for use in their own
        marketing at any time.
      </p>
      <h2>Links to Other Sites</h2>
      <p>
        My website may contain links to other websites of interest. However,
        once you have used these links to leave my site, you should note that I
        do not have any control over that other website. Therefore, I cannot be
        responsible for the protection and privacy of any information which you
        provide whilst visiting such sites and such sites are not governed by
        this privacy statement. You should exercise caution and look at the
        privacy statement applicable to the website in question.
      </p>
      <h2>Controlling your Information</h2>
      <p>
        You may choose to restrict the collection or use of your personal
        information in the following ways:
      </p>
      <ul>
        <li>
          whenever you are asked to fill in a form on the website, look for the
          box that you can click to indicate that you do not want the
          information to be used by anybody for direct marketing purposes if you
          have previously agreed to me using your personal information for
          direct marketing purposes, you may change your mind at any time by
          contacting me using the online form.
        </li>
        <li>
          I will not sell, distribute or lease your personal information to
          third parties unless I have your permission or are required by law to
          do so. I may use your personal information to send you promotional
          information about third parties which I think you may find interesting
          if you tell me that you wish this to happen.
        </li>
      </ul>
      <p>
        You may request details of personal information which I hold about you
        under the{" "}
        <a href="https://www.legislation.gov.uk/ukpga/2018/12/contents/enacted">
          The Data Protection Act 2018
        </a>
        . A small fee will be payable. If you would like a copy of the
        information held on you.
      </p>
      <p>
        If you believe that any information I'm holding on you is incorrect or
        incomplete, please contact me. I will promptly correct any information
        found to be incorrect.
      </p>
      <h2>The steps I take to keep your information private</h2>
      <p>
        Where I store your information in third-party services, I restrict
        access only to people who need it. I store passwords in a secure
        location, use a different, randomly generated password for each service,
        and never use the same password twice.
      </p>
      <p>
        My devices are protected by a passcode or fingerprint access. These
        computers ask for authentication whenever they’re started or after 15
        minutes of inactivity. My mobile devices are also protected by a
        fingerprint or facial recognition.
      </p>
      <h2>Removing your information</h2>
      <p>
        At any point, you may delete your account, however as this is
        potentially very destructive (removing license authorisations for any
        sites you hold) I ask that you contact me if you wish to request this. I
        will also remove data linked to you stored with my third parties.
      </p>
      <h2>How to complain</h2>
      <p>
        I take complaints very seriously. If you’ve any reason to complain about
        the ways I handle your privacy, please contact me.
      </p>
      <h2>Changes to the policy</h2>
      <p>
        All changes to this police are effective from the moment they are live.
      </p>
    </div>
  </Layout>
)

export default Development
